<template>
    <section class="content">
        <div class="container-fluid">
          <div class="card">
            <div class="card-header">
                <h3 class="card-title">Informasi Akun</h3>
            </div>
            <!-- form start -->
    <form role="form" id="form_user" @submit.prevent="submitForm">
        <div class="card-body">
            <div class="row">
        <div class="col-sm-6">
            <div class="form-group">
                <label for="exampleInputEmail1">Username</label>
                <input type="text" value="" class="form-control" v-model="form.username" placeholder="Nama" readonly />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Nama</label>
                <input type="text" value="" class="form-control" v-model="form.name" placeholder="Nama" />
                <input type="hidden" v-model="form.id" id="i_id" value="" name="i_id" />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">No Telepon</label>
                <input type="number" value="" class="form-control" v-model="form.tel" placeholder="Nomor Telepon" />
            </div>
            <div class="form-group">
                <label for="exampleInputEmail1">Email</label>
                <input type="email" value="" class="form-control" v-model="form.email" placeholder="Alamat Email" readonly />
            </div>
        </div>
        <div class="col-sm-6">
        <label for="exampleInputEmail1">Gambar Profile</label>
        <div class=" card-body p-1 user-profile <?=$user->image_id?'':'empty-profile'?> ">
        <img style="object-fit: cover; height: 100px; width: 100%" alt="image" id="imgPreview" class="img-fluid img-responsive" :src="url + '/uploads/default/unknown_user.jpg'" />
        <div id="loading-avatar" class="center-profile">
            <div class="btn-profile">
                <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
        <div id="remove-avatar" class="center-profile">
            <a id="del-avatar" class="btn-profile remove-profile text-danger" title="Hapus Foto" @click="removeAvatar"><i class="fa fa-times"></i></a>
        </div>
        </div>
        <input name="file" type="file" id="inputFile" class="inputfile" @change="uploadFoto(this)" />
        <label for="file" class="btn btn-block btn-success" @click="browsefile()"><i class="fa fa-image"></i> Pilih Foto</label>
      </div>
      </div>
    <hr />
    <div class="row">
        <div class="form-group col-md-6">
        <label for="exampleInputPassword1">Password Lama</label>
        <!-- <input type="password" class="form-control" name="i_pwd" placeholder="Password" v-model="form.oldpass" minlength="8" /> -->
          <div class="input-group">
          <input type="password" name="oldpass" id="oldpass" 
            class="form-control" autocomplete="off" data-toggle="password"
            v-model="form.oldpass" minlength="8" placeholder="password lama" />
          <div class="input-group-append">
            <span class="input-group-text input-password-hide" style="cursor: pointer;">
              <i class="fa fa-eye"></i>
            </span>
          </div>
          </div>
        </div>
        <div class="form-group col-md-6"></div>
        <div class="form-group col-md-6">
            <label for="exampleInputPassword1">Password Baru</label>
            <!-- <input type="password" class="form-control" name="i_pwd" placeholder="Password" v-model="form.newpass" minlength="8" /> -->
          <div class="input-group">
          <input type="password" name="newpass" id="newpass" 
            class="form-control" autocomplete="off" data-toggle="password"
            v-model="form.newpass" minlength="8" placeholder="password baru" />
          <div class="input-group-append">
            <span class="input-group-text input-password-hide" style="cursor: pointer;">
              <i class="fa fa-eye"></i>
            </span>
          </div>
          </div>
        </div>
        <div class="form-group col-md-6">
            <label for="exampleInputPassword1">Konfirmasi Password Baru</label>
            <!-- <input type="password" class="form-control" name="i_pwdconfirm" placeholder="Konfirmasi Password" v-model="form.newpassc" minlength="8" /> -->
          <div class="input-group">
          <input type="password" name="newpassc" id="newpassc" 
            class="form-control" autocomplete="off" data-toggle="password"
            v-model="form.newpassc" minlength="8" placeholder="konfirmasi password" />
          <div class="input-group-append">
            <span class="input-group-text input-password-hide" style="cursor: pointer;">
              <i class="fa fa-eye"></i>
            </span>
          </div>
          </div>
        </div>
    </div>
        </div>
        <div class="card-footer form-actions">
            <button type="submit" id="tb_save" data-loading-text="Simpan..." class="btn btn-primary" :disabled="loading">
                <i class="fa fa-pen"></i> Simpan
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            </button>
        </div>
    </form>
          </div>
        </div>
    </section>
</template>
<script>
import { auth, authFetch } from "@/libs/hxcore";
import $ from "jquery";
import Swal from 'sweetalert2';
import eventBus from '@/eventBus';

export default {
    name: 'Profile',
    data: () => ({
        ujian: {},
        info: [],
        url: "",
        form: {
        selected: "id",
        userId: "",
        name: "",
        username: "",
        email: "",
        tel: "",
        id: "",
        oldpass: "",
        newpass: "",
        newpassc: "",
      },
      loading: false,
    }),
    created: function () {
      this.url = window.$apiUrl;
      var info = auth.user();
      console.log(info);
      if(info)this.info = info;
      this.loadUser();
    },
    methods: {
      loadUser(){
        authFetch(`/users/profile`).then((res) => {
          res.json().then((json) => {
            this.form.name = json.display_name;
            this.form.email = json.email;
            this.form.tel = json.no_hp;
            this.form.id = json.id;
            this.form.username = json.username;

            if (!json.file_name) {
              $("#imgPreview").attr(
                "src",
                window.$apiUrl+"/uploads/default/unknown_user.jpg"
              );
            } else {
              // $("#imgPreview").attr(
              //   "src",
              //   window.$apiUrl+"/uploads/" +
              //     json.file_path +
              //     "/small/" +
              //     json.file_name
              // );
              $("#imgPreview").attr("src", this.info.img_url);
            }
          });
        });
      },
      submitForm: function (ev) {
      // const e = this.$refs;
      let self = this;
      if (self.form.newpass != self.form.newpassc) {
        Swal.fire("Proses gagal", `Password baru tidak sesuai dengan konfirmasi password!`, "error");
      } else {
        this.loading = true;
        var data = Object.keys(self.form)
          .map(
            (key) =>
              encodeURIComponent(key) + "=" + encodeURIComponent(self.form[key])
          )
          .join("&");
        var urlSubmit = "/users/profile";
        if (self.form.id) {
          self.method = "PUT";
          urlSubmit = "/users/profile/" + self.form.id;
        } else {
          self.method = "POST";
        }
  
        authFetch(urlSubmit, {
          method: self.method,
          body: data,
        })
          .then((res) => {
            this.loading = true;
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
          })
          .then((js) => {
            this.loading = false;
            self.errors = [];
            if (js.success) {
              Swal.fire({
                title: 'Update Berhasil',
                icon: 'success',
                text: 'Data profil sudah diudate',
                showCloseButton: true
              });
              self.form.oldpass = "";
              self.form.newpass = "";
              self.form.newpassc = "";
              self.loadUser();
            }
            else{
              Swal.fire({
                title: 'Update Gagal',
                icon: 'error',
                text: js.message,
                showCloseButton: true
              });
              // self.loadUser();
            }
          });
      }
      ev.preventDefault();
      },
      browsefile: function (argument) {
        document.getElementById("inputFile").click();
      },
      uploadFoto: function (file) {
        var info = auth.user();
        var file_data = $("#inputFile").prop("files");
        var form_data = new FormData();
        var fileInput = $("#inputFile")[0];
        this.readURL(fileInput);
        $.each(fileInput.files, function (k, file) {
          form_data.append("file", file);
        });
        $("#loading-avatar").show();
        form_data.append("subdir", "/profile_upload/" + info.id);
        form_data.append("id", info.id);

        authFetch("/users/profile/upload_data", {
          method: "POST",
          body: form_data,
          headers: {
            "Content-Type": null,
          },
        })
          .then((res) => {
            if (res.status === 201) {
            } else if (res.status === 400) {
            }
            return res.json();
          })
          .then((js) => {
            // localStorage['APP_UDATA']['img_url'] = '';
            this.errors = [];
            if (!js.status) {
              for (var key in js.details) {
                if (js.details.hasOwnProperty(key)) {
                  this.errors.push(js.details[key]);
                }
              }
              return;
            }
            $("#loading-avatar").hide();
            var tokenData = localStorage['APP_TOKEN'];
            var arrToken = JSON.parse(tokenData);
            var newToken = fetch(window.$apiUrl+'/login/update_token', {
                method: 'POST',
                body: window.$crossDomain!==false?arrToken['refreshToken']:''
            })
            .then(r => {
                return r.json()
            }).catch(e =>{
            });
            arrToken = newToken;
            localStorage['APP_TOKEN'] = JSON.stringify(arrToken);
            eventBus.$emit('changeFoto', js);
          });
      },
      readURL: function (input) {
          if (input.files && input.files[0]) {
              var reader = new FileReader();

              reader.onload = function (e) {
                  $("#imgPreview").attr("src", e.target.result);
              };

              reader.readAsDataURL(input.files[0]); // convert to base64 string
          }
      },
      removeAvatar: function () {
          var info = auth.user();
          authFetch("/users/profile/remove_profile/" + info.id, {
              method: "POST",
              headers: {
                "Content-Type": null,
              },
          })
          .then((res) => {
              if (res.status === 201) {
                  
              } else if (res.status === 400) 
              {
                  
              }
            return res.json();
          })
          .then((js) => {
            $("#img_id").val("");
            $("#img_code").val("");
            $("#imgPreview").attr(
              "src",
              window.$apiUrl+"/uploads/default/unknown_user.jpg"
            );
            $(".user-profile").addClass("empty-profile");
          });
      },
    },
    mounted(){
        // this.loadUjian();
    },
}
$(function () {
	$('[data-toggle="password"]').each(function () {
		var input = $(this);
		var eye_btn = $(this)
			.parent()
			.find(".input-group-text");
		eye_btn.css("cursor", "pointer").addClass("input-password-hide");
		eye_btn.on("click", function () {
			if (eye_btn.hasClass("input-password-hide")) {
				eye_btn
					.removeClass("input-password-hide")
					.addClass("input-password-show");
				eye_btn
					.find(".fa")
					.removeClass("fa-eye")
					.addClass("fa-eye-slash");
				input.attr("type", "text");
			} else {
				eye_btn
					.removeClass("input-password-show")
					.addClass("input-password-hide");
				eye_btn
					.find(".fa")
					.removeClass("fa-eye-slash")
					.addClass("fa-eye");
				input.attr("type", "password");
			}
		});
	});
});
</script>
<style type="text/css">
.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    /*background-color: black;*/
    display: inline-block;
}

.inputfile+label {
    cursor: pointer;
    /* "hand" cursor */
}

.inputfile:focus+label {
    outline: 1px dotted #000;
    outline: -webkit-focus-ring-color auto 5px;
}

.user-profile .center-profile {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    transition: opacity 0.3s;
    line-height: 34px;
    text-align: center;
    text-decoration: none;
}

.user-profile .btn-profile {
    display: block;
    width: 36px;
    height: 36px;
    transition: opacity 0.3s;
    border-radius: 50%;
    background-color: #fff;
    color: #606975;
    line-height: 34px;
    box-shadow: 0 1px 5px 0 rgb(0 0 0 / 20%);
    cursor: pointer;
}

.user-profile .remove-profile {
    /*visibility: hidden !important;*/
    cursor: pointer;
}
</style>